<template>
  <div>
    <div class="flexRow justify-content-end">
      <el-button type="primary" @click="openBox(1)">添加</el-button>
    </div>
    <div class="marginTop">
      <el-table border style="width:100%;" :data="list">
        <el-table-column prop="groupName" label="分组名" align="center">
        </el-table-column>
        <el-table-column prop="title" label="一级页面" align="center">
        </el-table-column>
        <el-table-column label="一级页面接口" align="center">
          <template slot-scope="scope">
            <div v-for="(item,index) in scope.row._child" class="flexRow align-item-center justify-content-between">
              <span>{{item.title}}</span>
              <div>
                <el-button type="text" @click="openBox1(2,scope.$index,index)">编辑</el-button>
                <el-button type="text" @click="delItem(item.id)">删除</el-button>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <span class="editBtn mRight" @click="openBox1(1,scope.$index)">添加方法</span>
            <span class="editBtn mRight" @click="openBox(2,scope.$index)">编辑</span>
            <span class="editBtn" @click="delItem(scope.row.id)">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--编辑、新建一级页面-->
    <el-dialog :visible.sync="showBox" :close-on-click-modal="false" width="30%">
      <el-form label-width="90px">
        <el-form-item label="选择分组">
          <el-select style="width:100%" clearable v-model="groupId">
            <el-option v-for="(opt,optIndex) in nodeArr" :key="optIndex" :label="opt.name" :value="opt.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="页面名称">
          <el-input placeholder="请输入页面名称" v-model="title" clearable></el-input>
        </el-form-item>
        <el-form-item label="路径名称">
          <el-input placeholder="请输入路径名称" v-model="page" clearable></el-input>
        </el-form-item>
      </el-form>
      <div class="marginTop flexRow justify-content-center">
        <el-button type="primary" @click="subMsg(1)">提交</el-button>
      </div>
    </el-dialog>
    <!--新建、编辑一级页面权限-->
    <el-dialog :visible.sync="showSubBox" width="30%" :close-on-click-modal="false">
      <el-form label-width="90px">
        <el-form-item label="接口名称">
          <el-input placeholder="请输入接口名称" v-model="name" clearable></el-input>
        </el-form-item>
        <el-form-item label="方法名">
          <el-input placeholder="请输入方法名" v-model="title" clearable></el-input>
        </el-form-item>
      </el-form>
      <div class="marginTop flexRow justify-content-center">
        <el-button type="primary" @click="subMsg(2)">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: "addSubNode",
    data(){
      return{
        title:"",
        groupId:"",
        page:"",
        name:"",
        list:[],
        showBox:false,
        showSubBox:false,
        nodeArr:[],
      }
    },
    created(){
      sessionStorage.setItem('dActive','addSubNode');
      this.getParentNode()
    },
    methods:{
      //获取父节点
      async getParentNode(){
        let res = await this.mainFun('AdminGroup/getList',{list_rows:50});
        this.nodeArr = res.data.data;
        this.getList();
      },
      //获取子节点
      async getList(){
        let res = await this.mainFun('AdminNode/node',{list_rows:50});
        console.log(res)
        let data1 = res.data[0];
        let data2 = res.data[1];
        let idArr = [],valueArr = [];
        for(let k in data1){
          idArr.push(k);
          valueArr.push(data1[k])
        }
        for(let i in data2){
          let index = idArr.indexOf(data2[i].id+'');
          if(index > -1){
            data2[i].subList = valueArr[index]
          }
        }
        let list = [];
        for(let item of data2){
          for(let subItem of item.subList){
            let obj = {
              groupName:item.name,
              groupId:item.id,
            }
            Object.assign(obj,subItem);
            list.push(obj)
          }
        }
        this.list = list;
      },
      //清除信息
      clearInfo(){
        this.groupId = '';
        this.title = '';
        this.page = '';
        this.name = '';
      },
      //打开弹窗
      openBox(index,tabIndex){
        if(index == 1){
          this.eid = "";
          this.clearInfo();
        }else{
          this.eid = this.list[tabIndex].id;
          this.title = this.list[tabIndex].title;
          this.page = this.list[tabIndex].page;
          this.groupId = this.list[tabIndex].group_id;
        }
        this.showBox = true;
      },
      openBox1(type,index,subIndex){
        if(type == 1){
          this.pid = this.list[index].id;
          this.eeid = "";
          this.title = "";
          this.name = "";
        }else{
          this.eeid = this.list[index]._child[subIndex].id;
          this.title = this.list[index]._child[subIndex].title;
          this.name = this.list[index]._child[subIndex].name;
        }
        this.showSubBox = true;

      },
      //添加或编辑一级页面
      subMsg(type){
        let params = {},url = 'AdminNode/';
        if(type == 1){
          if(this.groupId == ""){
            this.$message.warning("请选择分组");
            return;
          }
          if(this.title == ""){
            this.$message.warning("请填写页面名称");
            return;
          }
          if(this.page == ""){
            this.$message.warning("请填写路径名称");
            return;
          }
          params = {
            group_id:this.groupId,
            pid:1,
            type:1,
            level:2,
            title:this.title,
            page:this.page
          }
          if(this.eid){
            params.id = this.eid;
            url += 'edit'
          }else{
            url += 'add'
          }
        }else{
          if(this.title == ""){
            this.$message.warning("请填写方法名");
            return;
          }
          if(this.name == ""){
            this.$message.warning("请填写接口名称");
            return;
          }
          params = {
            pid:this.pid,
            type:0,
            level:3,
            title:this.title,
            name:this.name
          };
          if(this.eeid){
            params.id = this.eeid;
            url += 'edit';
          }else{
            url += 'add'
          }
        }
        this.$request(url,params).then(res=>{
          if(res.data.code == 1){
            this.getList();
            this.showBox = false;
            this.showSubBox = false;
            this.$message.success(res.data.message)
          }else{
            this.$message.success(res.data.message)
          }
        })
      },
      //删除一级页面或接口权限
      delItem(id){
        this.$confirm('是否确认删除当前信息','提示',{
          confirmButtonText:"确定",
          cancelButtonText:"取消",
          type:"warning"
        }).then(async ()=>{
          let res = await this.mainFun('AdminNode/del',{id});
          if(res.data.code == 1){
            this.$message.success(res.data.message);
            this.getList()
          }else{
            this.$message.error(res.data.message);
          }
        })
      },
    }
  }
</script>

<style scoped lang="less">
  .listCell{
    padding:15px;
    width:240px;
    box-sizing: border-box;
    border:2px dashed #329DFF;
    border-radius: 5px;
    font-size:16px;
    color:#329DFF;
    margin:0 15px 15px 0;
  }
</style>
